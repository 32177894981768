.profileImage {
    width: 40%;
}

.AboutInfo {
    width: 400px;
}

/* ----------- Iphone Screen Reponsiveness ----------- */
@media only screen and (max-device-width: 375px) 
and (max-device-height: 812px)
and (-webkit-min-device-pixel-ratio: 2) {
    .aboutHeaderContainer {
        flex-direction: column;
        align-items: center;
    }

    .aboutBodyContainer {
        flex-direction: column;
    }
    
    .about-card {
        margin-top: 30px;
    }

    .profileImage {
        width: 70%;
    }
}

/* ----------- Ipad Portrait and Landscape ----------- */
@media only screen and (min-device-width: 500px) 
and (max-device-width: 768px) 
and (max-device-height: 1024px) 
and (-webkit-min-device-pixel-ratio: 1)
and (-webkit-min-device-pixel-ratio: 2) {
    .aboutBodyContainer {
        flex-direction: column;
    }
}

/* ----------- Ipad Pro Portrait and Landscape ----------- */
@media only screen 
and (min-device-width: 1024px) 
and (min-device-height: 1336px)
and (-webkit-min-device-pixel-ratio: 1)
and (-webkit-min-device-pixel-ratio: 2) {
    .aboutBodyContainer {
        flex-direction: column;
    }
}