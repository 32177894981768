.inner {
    display: flex;
    justify-content: space-evenly;
    padding-top: 8%;
    padding-bottom: 100px;
    background: linear-gradient(to right, #101828, #2c425b);
}

.intro-excerpt h2, .intro-2 h2 {
    font-size: 27px;
    font-weight: 500;
    line-height: 60px;
    letter-spacing: 1px;
    text-align: left;
    font-family: 'futura-medium';
    color: #ffffff;
    font-size: '1.8rem';
}
  
.intro-post {
    margin: auto;
    overflow: hidden;
    margin: auto;
}

.planet {
    width: 50%;
    height: auto;
    overflow: visible;
    z-index: 2;
    transform: translateY(-100px);
}

.intro-title {
    font-family: futura-medium;
    font-size: 8rem;
    letter-spacing: 0.1rem;
    line-height: 1.2;
    color: #ffffff;
    margin-bottom: 2.5rem;
    text-shadow: 0 10px 30px rgba(2, 11, 22, 0.3);
}

.intro {
    margin-left: 5%;
    margin-right: 5%;
}

@media only screen and (max-width: 768px) {
    .intro {
        margin: 0;
    }

    .inner {
        flex-direction: column;
        align-items: center;
        padding-top: 150px;
        padding-bottom: 20px;
    }
    
    .intro-title {
        font-size: 50px;
        margin-left: 0;
        text-align: center;
        margin-right: 0;
    }

    .intro-excerpt h2 {
        margin: 0;
        text-align: left;
        padding-left: 30px;
        font-size: 40px;
    }

    .intro-2 h2 {
        margin: 0;
        text-align: left;
        font-size: 40px;
        padding-left: 30px;
    }

    img.planet {
        position: absolute;
        transform: translateY(-280px);
    }

}

/* ----------- Iphone Screen Reponsiveness ----------- */
@media only screen and (max-device-width: 375px) 
and (max-device-height: 812px)
and (-webkit-min-device-pixel-ratio: 2) {
    .intro-title {
        font-size: 50px;
    }

    .intro-excerpt h2 {
        font-size: 30px;
        overflow-wrap: break-word;
        text-align: left;
        padding-left: 30px;
    }

    .intro-2 h2 {
        font-size: 30px;
        overflow-wrap: break-word;
        text-align: left;
        padding-left: 30px;
    }

    .inner {
        flex-direction: column;
        align-items: center;
    }

    img.planet {
        position: absolute;
        transform: translateY(-300px);
    }
}

/* ----------- Ipad Portrait and Landscape ----------- */
@media only screen and (min-device-width: 500px) 
and (max-device-width: 768px) 
and (max-device-height: 1024px) 
and (-webkit-min-device-pixel-ratio: 1)
and (-webkit-min-device-pixel-ratio: 2) {
    .intro {
        margin: 0;
    }

    .intro-title {
        font-size: 50px;
        text-align: center;
    }

    .intro-excerpt h2 {
        font-size: 30px;
        overflow-wrap: break-word;
    }

    .intro-2 h2 {
        font-size: 30px;
        overflow-wrap: break-word;
    }

    .inner {
        flex-direction: column;
        align-items: center;
        padding-top: 400px;
        padding-bottom: 80px;
    }

    img.planet {
        position: absolute;
        transform: translateY(-350px);
    }
}

/* ----------- Ipad Pro Portrait and Landscape ----------- */
@media only screen 
and (min-device-width: 1024px) 
and (min-device-height: 1336px)
and (-webkit-min-device-pixel-ratio: 1)
and (-webkit-min-device-pixel-ratio: 2) {
    .intro {
        margin: 0;
    }

    .intro-title {
        font-size: 50px;
        text-align: center;
    }

    .intro-excerpt h2 {
        font-size: 30px;
        overflow-wrap: break-word;
    }

    .intro-2 h2 {
        font-size: 30px;
        overflow-wrap: break-word;
    }

    .inner {
        flex-direction: column;
        align-items: center;
        padding-top: 400px;
        padding-bottom: 80px;
    }

    img.planet {
        position: absolute;
        transform: translateY(-350px);
    }
}