html, body {
    margin: 0;
    width: 100%;
}

.post-feed {
    display: flex;
    justify-content: space-evenly;
    padding-top: 40px;
    padding-left: 70px;
    padding-right: 70px;
}

img.responsive {
    max-height: 100%;
    max-width: 100%;
}

.site-footer {
    position: relative;
    color: #fff;
    background: rgba(255, 253, 238, 0.5);
    align-content: center;
    text-align: center;
    margin: auto;
}