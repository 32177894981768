.res-greeting {
    font-size: 25px;
    font-weight: bold;
}

.divider {
    max-width: calc(1150px - (50px * 2));
}
.backToTop {
    text-align: center;
    max-width: calc(1150px - (50px * 2));
    margin: 0 auto;
}

a:hover {
    color: #6C6C6C;
}

/* ----------- Iphone Screen Reponsiveness ----------- */
@media only screen and (max-device-width: 375px) 
and (max-device-height: 812px)
and (-webkit-min-device-pixel-ratio: 2) {
    .responsive-res-emoji {
        visibility: hidden;
    }

    .responsive-section {
        flex-direction: column;
        padding-top: 10%;
    }

    .responsive-certification {
        flex-direction: column;
        padding-bottom: 0px;
    }
    
    .certificate {
        padding-bottom: 40px;
    }
}

/* ----------- Ipad Portrait and Landscape ----------- */
@media only screen and (min-device-width: 500px) 
and (max-device-width: 768px) 
and (max-device-height: 1024px) 
and (-webkit-min-device-pixel-ratio: 1)
and (-webkit-min-device-pixel-ratio: 2) {
    .responsive-section {
        flex-direction: column;
        padding-top: 10%;
    }

    .responsive-certification {
        flex-direction: column;
        padding-bottom: 0px;
    }

    .certificate {
        padding-bottom: 40px;
    }
}

/* ----------- Ipad Pro Portrait and Landscape ----------- */
@media only screen 
and (min-device-width: 1024px) 
and (min-device-height: 1336px)
and (-webkit-min-device-pixel-ratio: 1)
and (-webkit-min-device-pixel-ratio: 2) {
    .responsive-certification {
        flex-direction: column;
        padding-bottom: 0px;
    }

    .certificate {
        padding-bottom: 40px;
    }
}