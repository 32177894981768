/* --------------------------------------------------------------------------

                              NavBar Links

 -------------------------------------------------------------------------- */
  /* FLaG{HMm_1_D1dnT_Th1Nk_Y0Ud_Ch3Ck_H3RE} */
.NavBarMenu a {
  font-family: futura-medium;
  font-style: normal;
  font-weight: normal;
  text-transform: uppercase;
  color: #fffafa;
  text-decoration: none;
  display: grid;
  align-items: center;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
}

.NavBarMenu a:hover {
  color: #fffafa;
}

.NavBarMenu a:link {
  text-decoration: none;
}

.NavBarMenu a::after {
    content: '';
    width: 0%;
    height: 2px;
    background: #fffafa;
    margin: auto;
    transition: 0.3s;
}

.NavBarMenu a:hover:after {
    width: 100%;
}

.NavBarBrand {
  font-family: futura-medium;
  color: #fffafa;
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;

}

.NavBarBrand:hover {
    text-decoration: none;
    color: #fffafa;
}

/* --------------------------------------------------------------------------

                              SideBar Links

 -------------------------------------------------------------------------- */
.SideBarMenu a {
  display: grid;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  text-decoration: none;
  list-style: none;
  transition: 0.2s ease-in-out;
  text-decoration: none;
  color: #595757;
  cursor: pointer;
}

.SideBarMenu a::after {
  content: '';
  width: 0%;
  height: 2px;
  background: #595757;
  margin: auto;
  transition: 0.3s;
  transform: translateY(-40px);
}

.SideBarMenu a:hover:after {
    width: 100%;
}

/* --------------------------------------------------------------------------

                              Content Postcard

 -------------------------------------------------------------------------- */
.post-card { 
  overflow: hidden;
  margin: 0 50px 40px;
  height: 550px;
  min-height: 300px;
  background: #e6e6ea 50%;
  background-size: cover;
  border-radius: 15px;
  box-shadow: 1px 1px 38px rgb(225 225 225 / 15%);
  flex: 1;
  transition: all .5s ease;
}

.post-card:hover {
  box-shadow: rgba(39,44,49,0.07) 8px 28px 50px, rgba(39, 44, 49, 0.04) 1px 6px 12px;
  transition: all 0.4s ease;
  transform: translate3D(0, -1px, 0) scale(1.02);
}

.post-card-content-link {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  display: block;
  padding: 25px 25px 0;
  color: #15171a;
  text-decoration: none;
}

.post-card-content-link:hover {
  text-decoration: none;
  color: #000000;
}

.post-card-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.post-card-tags {
  font-family: 'Comfortaa';
  display: block;
  margin-bottom: 4px;
  color: #738a94;
  font-size: 0.8rem;
  line-height: 1.15em;
  font-weight: 500;
  letter-spacing: .5px;
  text-transform: uppercase;
}

.post-card-title {
  font-family: 'Segoe UI', Geneva, Verdana, sans-serif;
  font-size: 1.5rem;
  letter-spacing: .5px;
  text-align: left;
  padding-top: 10px;
}

.post-card-excerpt p{
  font-family: 'Segoe UI', Geneva, Verdana, sans-serif;
  text-align: left;
  letter-spacing: .5px;
}

img.post-card-image {
  width: 100%;
  height: 355px;
  background: #c5d2d9 no-repeat 50%;
  border-bottom: solid 2px;
  border-color: rgb(59 100 160);
  object-fit: cover;
  overflow: hidden;
  position: static;
}

.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  text-align: left;
}

/* ----------- Desktop Screens ----------- */
@media only screen and (max-width: 768px){
  .post-feed { 
      flex-flow: column;
  }

  .post-card-title, .post-card-tags{
      text-align: center;
  }


}

/* ----------- Laptops (Non-Retina & Retina Screens) ----------- */
@media screen 
and (min-device-width: 1200px) 
and (max-device-width: 1600px) 
and (-webkit-min-device-pixel-ratio: 1)
and (-webkit-min-device-pixel-ratio: 2)
and (min-resolution: 192dpi) { 
  .post-feed { 
      flex-flow: row;
  }

  .post-card-title, .post-card-tags{
      text-align: center;
  }
}

/* ----------- Iphone Screen Reponsiveness ----------- */
@media only screen and (min-device-width: 320px) 
and (max-device-width: 480px)
and (-webkit-min-device-pixel-ratio: 2) {
  .post-feed { 
      flex-flow: column;
  }

  .post-card-title, .post-card-tags{
      text-align: center;
  }

}

/* ----------- Ipad Portrait and Landscape ----------- */
@media only screen 
and (min-device-width: 768px) 
and (max-device-width: 1024px) 
and (min-device-width: 834px) 
and (max-device-width: 1112px)
and (-webkit-min-device-pixel-ratio: 1)
and (-webkit-min-device-pixel-ratio: 2) {
  .post-feed { 
      flex-flow: column;
  }

  .post-card-title, .post-card-tags{
      text-align: center;
  }
}
/* --------------------------------------------------------------------------

                              Footer

 -------------------------------------------------------------------------- */
.footer {
  padding: 20px 0;
  color: #000000;
  background-color: #fffdfa;
}

.footer .row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  text-align: left;
  padding-top: 30px;
  padding-bottom: 10px;
}

.footer h3 {
  margin-top: 0px;
  margin-bottom: 12px;
  font-weight: bold;
  font-size: 25px;
}

.footer ul {
  padding:0;
  list-style:none;
  line-height:1.6;
  font-size:16px;
  margin-bottom:0;
}

.footer ul a {
  color:inherit;
  text-decoration:none;
  opacity:0.6;
}

.footer ul a:hover {
  opacity:0.8;
}

.footer .item.text {
  margin-bottom:36px;
}

.footer .item.text p {
  opacity:0.6;
  margin-bottom:0;
}

.footer .item.social {
  text-align:center;
}

.footer .item.social > a {
  font-size:20px;
  width:36px;
  height:36px;
  line-height:36px;
  display:inline-block;
  text-align:center;
  border-radius:50%;
  box-shadow:0 0 0 1px rgba(0, 0, 0, 0.4);
  margin:0 8px;
  color:#000000;
  opacity:0.75;
}

.footer .item.social > a:hover {
  opacity:0.9;
}

.footer .statement {
  text-align:center;
  padding-top:24px;
  opacity:0.3;
  font-size:13px;
  margin-bottom:0;
}

@media (max-width:767px) {
  .footer .item:not(.social) {
      text-align:center;
      padding-bottom:20px;
  }
}

@media (max-width:767px) {
  .footer .item.text {
      margin-bottom:0;
  }
}

@media (max-width:991px) {
  .footer .item.social {
      text-align:center;
      margin-top:20px;
  }
}





.post-link {
  color: #4285F4;
}

.post-link:hover {
  color: #4285F4;
  text-decoration: none;
}